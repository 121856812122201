<template>
  <div class="view-home position-relative parents-list-item">
    <div class="container">
      <div class="col-12 col-lg-6 mx-lg-auto item-block">
        <el-image
            style="width: 100%; height: 100%"
            :src="this.parentsListItem.image_path"
            fit="fit"></el-image>
        <div class="description">

        </div>
        <div class="title">
          {{ this.parentsListItem.title }}
        </div>
        <div class="description">
          {{ this.parentsListItem.description }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      parentsListItem: {},
    }
  },
  methods: {

  },
  mounted() {

    console.log(555,this.$route.params.id)

    const id = this.$route.params.id
    this.$http.get(window.API_ROOT + `/api/parents/${id}`)
        .then(res => {
          this.parentsListItem = res.body
          this.parentsListItem.image_path = 'https://apiqabilet.elumiti.kz/storage/' + this.parentsListItem.image_path
          console.log('res', this.parentsListItem)
        })
  }


}

</script>

<style>

.item-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  padding-bottom: 40px;
}

.item-block .el-image {
  border-radius: 10px;
}

.item-block .title {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #343f68;
}

.item-block .description {
  color: #696984;
  font-size: 16px;
}


</style>
